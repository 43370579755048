<template>
  <section class="login-main">
    <div class="login-inner">
      <div class="login-l">
        <Sidebar />
      </div>
      <div class="login-r">
        <div class="login-box">
          <div></div>  <!-- Don't remove this empty div -->
          <div class="login-form">
            <h2>{{ $t('leads.sendYourLead')}}</h2>
            <v-form id="form">
                  <label class="font-weight-regular">
                    {{ $t('leads.companyName')}}
                  </label>
                  <v-text-field
                    hide-details="auto"
                    v-model="companyName"
                    solo
                    :placeholder="$t('leads.companyName')"
                    :error-messages="companyNameError"
                    @blur="$v.companyName.$touch()"
                    append-icon="mdi-city"
                    color="green  darken-4"
                    class="mb-4"
                  ></v-text-field>

                  <label class="font-weight-regular">
                    {{ $t('leads.representativeName')}}
                  </label>
                  <v-text-field
                    hide-details="auto"
                    v-model="representativeName"
                    solo
                    :placeholder="$t('leads.representativeName')"
                    :error-messages="representativeNameError"
                    @blur="$v.representativeName.$touch()"
                    append-icon="mdi-account-outline"
                    color="green  darken-4"
                    class="mb-4"
                  ></v-text-field>

                  <label class="font-weight-regular">
                    {{ $t('leads.representativePhone')}}
                  </label>
                  <v-text-field
                    hide-details="auto"
                    maxlength="14"
                    v-model="representativePhoneNumber"
                    :error-messages="representativePhoneNumberError"
                    :placeholder="$t('profile.representativePhonePlaceholder')"
                    @blur="$v.representativePhoneNumber.$touch()"
                    append-icon="mdi-cellphone"
                    v-to-us-format
                    solo
                    color="green  darken-4"
                    class="mb-4"
                  ></v-text-field>
                  <button :disabled="$v.$invalid && $v.$anyDirty" type="button" @click="submitLeadDetail" class="btn customBtn pattern-btn w-100">
                  <span> {{ $t("leads.submit") }}</span>
                </button>
                </v-form>

            <div class="form-link">
              <p>{{ $t("leads.dontHaveAnAccount") }} <button @click="$router.push('/login')">{{ $t("leads.signin") }}</button></p>
            </div>
          </div>
          <div class="login-footer">
            <div class="chrome-notification">
              {{ $t("login.browserSupportMessage") }}
              <img src="../assets/chrome.png" height="17" />
            </div>
            <p> {{ $t("login.allRightsReserved") }}
              <button @click="$router.push('/terms-condition')">
                {{ $t("login.term&condition") }}
              </button>
            </p>
          </div>
          <div class="login-r-btm">
            <div class="phone-dtl">
              <a href="tel:+1 714 334 5505">
                <img
                  :src="!this.isDarkMode ? require('../assets/img/icon-phone-call-green.svg') : require('../assets/img/icon-phone-call-blue.svg')" />
                <p>
                  {{ $t("leads.forHelpCallAdriaonat") }}
                  <b>714-334-5505</b>
                </p>
              </a>
            </div>
            <div class="social-dtl">
              <ul>
                <li>
                  <a :href="VUE_APP_RDL_INVENTORY_LINK" target="_blank">
                    <img
                      :src="!this.isDarkMode ? require('../assets/img/union-green.svg') : require('../assets/img/union-blue.svg')" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_FACEBOOK_LINK" target="_blank">
                    <img
                      :src="!this.isDarkMode ? require('../assets/img/fb-green.svg') : require('../assets/img/fb-blue.svg')" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_INSTAGRAM_LINK" target="_blank">
                    <img
                      :src="!this.isDarkMode ? require('../assets/img/insta-green.svg') : require('../assets/img/insta-blue.svg')" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_RDL_WHATSAPP_LINK" target="_blank">
                    <img
                      :src="!this.isDarkMode ? require('../assets/img/whatsapp-green.svg') : require('../assets/img/whatsapp-blue.svg')" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_RDL_YOUTUBE_LINK" target="_blank">
                    <img
                      :src="!this.isDarkMode ? require('../assets/img/youtube-green.svg') : require('../assets/img/youtube-blue.svg')" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <template>
          <v-snackbar
      v-model="snackbar"
      absolute
      top
      :color="snackbarColor"
      right
      elevation="24"
      class="snack-alert"
    >
      {{ snackbarText }}
    </v-snackbar>
          <div class="text-center">
            <v-dialog v-model="dialog" width="500">
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  {{ $t("login.forceLogoutDialogTitle") }}
                </v-card-title>
                <v-card-text>
                  <div class="pt-4">
                    <h3>
                      {{ $t("login.forceLogoutDialogMessage") }}
                    </h3>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn height="50" medium @click="dialog = false" dense
                    class="green darken-4 green-gradient white--text" elevation="0">
                    {{ $t("login.cancel") }}
                  </v-btn>
                  <v-btn height="50" medium @click="forceLogin" dense class="green darken-4 green-gradient white--text"
                    elevation="0">
                    {{ $t("login.forceLogout") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>



<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Leads",
  components: {
    Sidebar: () => import("@/components/PublicAside"),
  },
  data() {
    return {
      snackbar: false,
      snackbarColor: "green darken-4",
      snackbarText: null,
      companyName: null,
      representativeName: null,
      representativePhoneNumber: null,
      VUE_APP_FACEBOOK_LINK: process.env.VUE_APP_FACEBOOK_LINK,
      VUE_APP_INSTAGRAM_LINK: process.env.VUE_APP_INSTAGRAM_LINK,
      VUE_APP_RDL_INVENTORY_LINK: process.env.VUE_APP_INVENTORY_URL,
      VUE_APP_RDL_YOUTUBE_LINK: process.env.VUE_APP_YOUTUBE_LINK,
      VUE_APP_RDL_WHATSAPP_LINK: process.env.VUE_APP_WHATSAPP_LINK,
    };
  },
  validations: {
    companyName: {
      required,
    },
    representativeName: {
      required,
    },
    representativePhoneNumber: {
      required,
      usFormat: (value) => {
        return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
      },
    },
  },
  computed: {
    ...mapGetters({
      isDarkMode: "isDarkMode",
    }),
    companyNameError() {
      const errors = [];
      if (!this.$v.companyName.$dirty) return errors;
      !this.$v.companyName.required &&
        errors.push(
          this.$t("requiredValidation", {
            field: this.$t("leads.companyName"),
          })
        );
      return errors;
    },
    representativeNameError() {
      const errors = [];
      if (!this.$v.representativeName.$dirty) return errors;
      !this.$v.representativeName.required &&
        errors.push(
          this.$t("requiredValidation", {
            field: this.$t("leads.representativeName"),
          })
        );
      return errors;
    },
    representativePhoneNumberError() {
      const errors = [];
      if (!this.$v.representativePhoneNumber.$dirty) return errors;
      !this.$v.representativePhoneNumber.required &&
        errors.push(
          this.$t("requiredValidation", {
            field: this.$t("leads.representativePhone"),
          })
        );

      !this.$v.representativePhoneNumber.usFormat &&
        errors.push(this.$t("leads.representativePhoneIsInvalid"));
      return errors;
    },
  },
  methods: {
    ...mapActions({
      createLead: "leads/createLead",
    }),
    async submitLeadDetail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit("setLoader", true);
        try {
          const payload = {
            company_name: this.companyName,
            representative_name: this.representativeName,
            representative_phone_number: this.representativePhoneNumber,
          };

          if (payload.representative_phone_number) {
            payload.representative_phone_number =
              payload.representative_phone_number.replace(/[^0-9]/gi, "");
          }

          await this.createLead(payload);
          this.companyName = null;
          this.representativeName = null;
          this.representativePhoneNumber = null;
          this.setSnackBarValues("Lead saved successfully!", false);
          this.$v.$reset();
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          this.setSnackBarValues(errorMessage, true);
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },
    setSnackBarValues(message, isError) {
      if (isError === true) {
        this.snackbarColor = "red lighten-1";
      }
      this.snackbar = true;
      this.snackbarText = message;
    },
  },
};
</script>
